.login-form {
  max-width: 300px;
}
.login-form-forgot {
  float: right;
}
.login-form-remember {
  float: left;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  background: #316ea6 !important;
  border-color: #316ea6 !important;
  border-radius: 5px;
}
.form-div {
  padding-top: 5%;
}
.login-header-style {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #316ea6;
  padding-bottom: 15px;
  text-align: left;
}
.or-register {
  padding-top: 20px;
}
.card-style {
  background: #fbfbfb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.input-box-style {
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05),
    inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  background: #ffffff !important;
  height: 38px !important;
}
.login-logo {
  width: 158px;
  height: 41.24px;
  margin-bottom: 23px;
}

.caption-header-style {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #316ea6;
  padding-bottom: 15px;
  text-align: center;
}

.logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 28px 16px 0;
  float: left;
}

.logo {
  background: rgba(255, 255, 255, 0.3);
}
.ant-layout-header {
  height: 10%;
  padding: 0 20px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 20px;
  background: #001529;
}
.ant-menu-dark .ant-menu-item > a {
  color: rgba(255, 255, 255, 0.65) !important;
}
.forced-white {
  color: rgba(255, 255, 255, 0.65) !important;
}

/*layout styles*/
.layout-style {
  padding: 20px 24px 25px;
}
.main-content-area {
  background: #fff;
  width: 100%;
  height: 100%;
  /* overflow-y: scroll; */
}
.main-content-area::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.main-content-area::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #306da5;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.ant-form-item-label > label {
  font-family: Signika Negative;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #316ea6;
  /* height: 22px !important; */
}

/*Input field styling*/
.ant-input {
  background: #ffffff;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05),
    inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px !important;
}
.ant-select-selector {
  background: #ffffff;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05),
    inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px !important;
}
.ant-input-number {
  background: #ffffff;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05),
    inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px !important;
}
.ant-picker {
  background: #ffffff;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05),
    inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px !important;
}
.ant-card-head-title {
  text-align: center;
  padding: 0 0;
}
/*Camel case place holders*/
.ant-input:placeholder-shown {
  text-transform: capitalize;
}
.ant-input-number-input:placeholder-shown {
  text-transform: capitalize;
}
.ant-form-item-has-error .ant-form-item-explain,
.ant-form-item-has-error .ant-form-item-split {
  font-size: 10.5px;
}
div.item {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  width: 120px;
}

.caption {
  display: block;
  color: #fff;
}
